const LoginForm = require('./loginForm');
const SignUpForm = require('./signUpForm');

class FrontDoorModal {
    constructor({
        onSuccessfulSubmit = null,
        loginTitle = null,
        signupTitle = null,
        initialAccountPath = null,
        initialAccountPathHide = false,
    } = {}) {
        const excludedFrontDoorPaths = ['/login', '/login/forgot', '/signup' , '/signup/iframe'];
        if (excludedFrontDoorPaths.includes(window.location.pathname)) {
            return;
        }

        this.modal = document.getElementById('frontdoor-modal');
        if (!this.modal) {
            console.info('Did not instantiate FrontDoor modal. Modal not present.');
            return;
        }
        
        const tplVars = this.modal.querySelector('#frontdoor-js-variables').dataset;

        this.onSuccessfulSubmit = (typeof onSuccessfulSubmit === 'function')
            ? (type, memberInfo) => onSuccessfulSubmit(type, memberInfo)
            : (type, memberInfo) => { return true; }

        let logInFormProps = { 
            isModal: true,
            onSuccessfulSubmit: (type, memberInfo) => this.onSuccessfulSubmit(type, memberInfo)
        };
        let signInFormProps = {
            onSuccessfulSubmit: (type, memberInfo) => this.onSuccessfulSubmit(type, memberInfo)
        };

        // Logged out job form. Provide extra details.
        if(window.location.pathname.includes('/jobs/post_job')){
            signInFormProps.initialAccountPath = 'basic';
            //Set default "post job" titles for the modals.
            signInFormProps.title = 'Sign Up to Post Your Job';
            logInFormProps.title = 'Log In to Post Your Job';
            //If there's privately invited talents override the title.
            if(tplVars.invitedTalent){
                if(Array.isArray(tplVars.invitedTalent)){
                    signInFormProps.title = `Sign Up to Invite ${tplVars.invitedTalent[0].name}`;
                    logInFormProps.title = `Log In to Invite ${tplVars.invitedTalent[0].name}`;
                }else{
                    const talentJSON = JSON.parse(tplVars.invitedTalent);
                    signInFormProps.title = `Sign Up to Invite ${talentJSON.name}`;
                    logInFormProps.title = `Log In to Invite ${talentJSON.name}`;
                }
            }
        }

        if (loginTitle) logInFormProps.title = loginTitle;
        if (signupTitle) signInFormProps.title = signupTitle;
        if (initialAccountPath) signInFormProps.initialAccountPath = initialAccountPath;
        if (initialAccountPathHide) signInFormProps.initialAccountPathHide = initialAccountPathHide;

        this.loginForm = new LoginForm(logInFormProps);
        this.signUpForm = new SignUpForm(signInFormProps);
        
        this.fixLegacyModals();
        this.attachEventListeners();

        document.addEventListener('voices:frontdoor:addGoogleEventListeners', e => {
            this.signUpForm.addGoogleEventListeners();
        });
    }

    setProps({
        onSuccessfulSubmit = null,
        loginTitle = null,
        signupTitle = null,
        initialAccountPath = null,
        initialAccountPathHide = false,
    } = {}) {
        if (onSuccessfulSubmit) {
            this.loginForm.setOnSuccessfulSubmit(onSuccessfulSubmit);
            this.signUpForm.setOnSuccessfulSubmit(onSuccessfulSubmit);
        }
        if (loginTitle) this.loginForm.title = loginTitle;
        if (signupTitle) this.signUpForm.title = signupTitle;
        this.signUpForm.setAccountPathHandling({ initialAccountPath, initialAccountPathHide })
    }

    resetProps() {
        this.resetTitles();
        this.resetOnSuccessfulSubmit();
        this.signUpForm.setAccountPathHandling({ initialAccountPath: null, initialAccountPathHide: false });
    }

    resetTitles() {
        this.loginForm.title = null;
        this.signUpForm.title = null;
    }

    resetOnSuccessfulSubmit() {
        this.loginForm.setOnSuccessfulSubmit(null);
        this.signUpForm.setOnSuccessfulSubmit(null);
    }

    /*
     * Janitorial function just to fix any links that are pointing to login or signup modals instead of front door.
     */
    fixLegacyModals(){
        const loginLinks = document.querySelectorAll('[data-target="#login-modal"]');
        const signUpLinks = document.querySelectorAll('[data-target="#signup-modal"]');
        loginLinks.forEach( link => {
            link.dataset.target = "#frontdoor-modal";
            link.dataset.door = "log-in";
        });
        signUpLinks.forEach( link => {
            link.dataset.target = "#frontdoor-modal";
            link.dataset.door = "sign-up";
        });
    }

    /*
     * Bind events for modal
     */
    attachEventListeners(){
        const _this = this;
        $(this.modal).on('show.bs.modal', function(e){
            if(e.relatedTarget){
                const whatDoor = e.relatedTarget.dataset.door;
                if(whatDoor === 'log-in'){
                    _this._decorateLogInDoor();
                }else{
                    _this._decorateSignUpDoor();
                }
            }
        });

        /*
         * Alternate between modals with footer links
         */
        this.loginForm.signUpLink.addEventListener('click', e => {
            e.preventDefault();

            const { emailValue } = this.loginForm;
            if (emailValue) {
                this.signUpForm.updateEmail(emailValue);
                this.signUpForm.form.querySelectorAll('[type="submit"]').forEach(submitBtn => submitBtn.disabled = false);
            }

            this._decorateSignUpDoor();
        });
        this.signUpForm.loginLink.addEventListener('click', e => {
            e.preventDefault();

            const signUpEmailValue = this.signUpForm.emailValue;
            if(signUpEmailValue){
                this.loginForm.updateEmail(signUpEmailValue);
            }

            this._decorateLogInDoor();
        });
    }

    /*
     * Open modal in sign up state.
     */
    openSignUpDoor(resetOnClose = false) {
        this._decorateSignUpDoor();
        $(this.modal).on('hidden.bs.modal', () => {
            if (resetOnClose) this.resetProps();
        });
        $(this.modal).modal('show');
    }

    /*
     * Open modal in sign up state.
     */
    openLogInDoor(resetOnClose = false) {
        this._decorateLogInDoor();
        $(this.modal).on('hidden.bs.modal', () => {
            if (resetOnClose) this.resetProps();
        });
        $(this.modal).modal('show');
    }
    
    /*
     * Populate sign up content in the modal.
     */
    _decorateSignUpDoor(){
        this.loginForm.hide();
        this.signUpForm.show();
    }

    /*
     * Populate login content in the modal.
     */
    _decorateLogInDoor(){
        this.signUpForm.hide();
        this.loginForm.show();
    }
}

module.exports = FrontDoorModal;